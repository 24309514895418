
/*input[type=number]::-webkit-inner-spin-button,*/
/*input[type=number]::-webkit-outer-spin-button {*/
/*    -webkit-appearance: none;*/
/*    margin: 0;*/
/*}*/


.hide-spinners::-webkit-inner-spin-button,
.hide-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}