body {
    margin: 0;
    color: #444;
    background: #fff;
    font-weight: 300;
    font-size: 12px;

}

textarea, select, input, button {
    outline: none;
}

a:link, a:visited {
    /*color: #3490dc;*/
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 0.8rem !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 0.8rem !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 0.8rem !important;
}

.tooltip {
    position: relative;
}

.tooltip:before,
.tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(52, 144, 220, 1);
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 35px;
    left: 20px;
    width: 0;
}

.tooltip:before {
    background: rgba(52, 144, 220, 1);
    border-radius: 2px;
    color: #fff;
    content: attr(data-title);
    font-size: 14px;
    padding: 6px 10px;
    top: 41px;
    white-space: nowrap;
}

/* the animations */
/* fade */
.tooltip.fade:after,
.tooltip.fade:before {
    transform: translate3d(0, -10px, 0);
    transition: all .15s ease-in-out;
}

.tooltip.fade:hover:after,
.tooltip.fade:hover:before {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

/* expand */
.tooltip.expand:before {
    transform: scale3d(.2, .2, 1);
    transition: all .2s ease-in-out;
}

.tooltip.expand:after {
    transform: translate3d(0, 6px, 0);
    transition: all .1s ease-in-out;
}

.tooltip.expand:hover:before,
.tooltip.expand:hover:after {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}

.tooltip.expand:hover:after {
    transition: all .2s .1s ease-in-out;
}

/* swing */
.tooltip.swing:before,
.tooltip.swing:after {
    transform: translate3d(0, 30px, 0) rotate3d(0, 0, 1, 60deg);
    transform-origin: 0 0;
    transition: transform .15s ease-in-out, opacity .2s;
}

.tooltip.swing:after {
    transform: translate3d(0, 60px, 0);
    transition: transform .15s ease-in-out, opacity .2s;
}

.tooltip.swing:hover:before,
.tooltip.swing:hover:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg);
}

.pretty input:checked ~ .state.p-custom label:after, .pretty.p-toggle .state.p-custom label:after {
    background-color: #6cb2eb !important;
}

.pretty.p-switch input:checked ~ .state.p-custom:before {
    border-color: #6cb2eb !important;
}

.dropzone-section, .dropzone-section-single{
    outline: none !important;
}

/*.dropzone-section:focus {*/
/*    outline: #6cb2eb auto 1px !important;*/
/*}*/

/*.dropzone-section:focus > p {*/
/*    color: #3490dc !important;*/
/*}*/

/*.dropzone-section-single{*/
/*    outline: none !important;*/
/*}*/

@-moz-keyframes spin {
    50% { -moz-transform: rotateX(90deg);}
}

@-webkit-keyframes spin {
    50% { -webkit-transform: rotateX(90deg); }
}

@keyframes spin {
    50% { -webkit-transform: rotateX(90deg); transform: rotateX(90deg);}
}

.spin-animation-hover:hover > :first-child {
    -webkit-animation: spin 0.3s ease-in-out;
    -moz-animation: spin 0.3s ease-in-out;
    animation: spin 0.3s ease-in-out;
}

.customer-tabs div{
    transition: all 0.2s ease-in-out;
}

.select-form-field, .datepicker-form-field, .input-form-field, .textarea-form-field{
    border-left: 2px solid transparent !important;
}

.select-form-field:focus-within, .datepicker-form-field:focus-within, .input-form-field:focus-within, .textarea-form-field:focus-within{
    border-left: 2px solid #6cb2eb !important;
}

.days div:nth-child(12n), .months div:nth-child(12n) {
    padding-right: 0 !important;
}

.years div:nth-child(8n){
    padding-right: 0 !important;
}

.table-row td div{
    padding: 0 !important;
}

#left{
    transform: scale(1);
    transition: all 300ms; /* Animation */
}

#left:hover {
    transform: scale(2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    transition: all 300ms; /* Animation */
}

.svg-wrapper>div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check-tab{
    transition: all 500ms ease-in-out; /* Animation */
}

.features-col, .damage-list-row{
    transition: all 300ms ease-in-out; /* Animation */
}

.damage-detail{
    transition: all 150ms ease-in-out;
}

.damages-summary-panel, #circular-wrapper {
    transition: all 50ms linear; /* Animation */
}

.summary-completed-text {
    transition: all 150ms linear; /* Animation */
}

.damages-counter:hover i, .damages-counter:hover span{
    color: #63b3ed;
}

.fadeIn {
    transition: all 1500ms linear; /* Animation */
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 17px solid transparent;

    /*border-left: 20px solid #bee3f8;*/
}

.feature-name {
    display: block;
    width: 166px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.color-name{
    display: block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.constructor-info{
    transition: left 200ms linear; /* Animation */
}

.construction-wrapper .construction-option:last-child{
    margin-bottom: 0 !important;
}

.placeholder-bold::-webkit-input-placeholder {
   font-weight: normal;
}

.placeholder-bold::-moz-placeholder {
    font-weight: normal;
}

.placeholder-bold::-ms-input-placeholder {
    font-weight: normal;
}

.placeholder-bold::placeholder {
    font-weight: normal;
}

.placeholder-xl::-webkit-input-placeholder {
    font-size: .875rem !important;
}



.placeholder-xl::-moz-placeholder {
    font-size: .875rem !important;
}

.placeholder-xl::-ms-input-placeholder {
    font-size: .875rem !important;
}

.placeholder-xl::placeholder {
    font-size: .875rem !important;
}