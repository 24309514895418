.app-menu {
    z-index: 50;
    position: absolute;
    height: 42px;
}

.selected-menu-item {
    float: left;
    min-height: 42px;
    max-height: 42px;
    color: white;
    padding-left: 20px;
    cursor: pointer;
    border-right: 2px solid #575757;
}

.item {
    float: left;
    color: white;
    transition: width 0.2s;
    min-height: 42px;
    max-height: 42px;
    cursor: pointer;

}

.menu-item-title {
    font-size: 10px;
    max-height: 8px;
    margin-top: 4px;
}

.menu-item-app {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 700;

}
