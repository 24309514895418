
.logoLogin {
    height: 132px;
    width: 248px;
    text-align: center;
    /*background-color: #f0f2f5;*/
}

.container{
    height: 100vh;
}

.content {
    padding: 52px 0 24px;
    width: 400px;
    margin: 0 auto;
    text-align: center;

}

.globalFooter {
    padding: 0 16px;
    margin: 48px 0 24px;
    text-align: center;
}


#components-form-login .login-form {
    max-width: 300px;
    padding-top: 20px;
}
#components-form-login .login-form-forgot {
    float: right;
}
#components-form-login .login-form-button {
    width: 100%;
}