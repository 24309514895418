


.triangle-right {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #3E97CB;
    position: relative;
    top: -1px;
}

.triangle-right-active {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #3E97CB;
    position: relative;
    top: -1px;
}

.triangle-right:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 19px solid #ffffff;
    position: absolute;
    top: -19px;
    right: 1px;
}

.triangle-right-active:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 19px solid #3E97CB;
    position: absolute;
    top: -19px;
    right: 1px;
}