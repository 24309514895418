
.app-header-menu {
    display: flex;
    flex-direction: row;
    height:42px;
    background-color: #474747;
}

.app-content-container {
    display: flex;
    flex-direction: row;
    /*background-color: #f7f7f8;*/
}

.app-menu-block {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.content-container-panel {
    width:  200px;
    color: white;
    background-color: #edf2f7;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 2px 0px 8px;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 2px 0px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 0px 8px;
    z-index: 11;
}

.block-active {
    background-color: #606f7b;

}

#menu-block:hover {
    background-color: #606f7b;
}

.content-container-content {
    display: flex;
    flex: 1;
    background-color: #f7fafc;
    overflow: scroll;
    height: calc(100vh - 75px);
}

.header-menu {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    width: auto;
    background-color: #474747;
    align-items: center;
    align-content: center;
    margin-left: 284px;

}


.footer {
    border: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    background: #474747;
    color: white;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    margin: 0;
    padding: 8px 20px;
    min-width: 100px;
    width: 100%;
    opacity: 1;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


.global-search-wrapper {
    padding-right: 50px;
}


.global-search {
    width: 100%;
}



.global-search-item-count {
    position: absolute;
    right: 16px;
}

.blocks-menu {
    width: 53px;
    background-color: #474747;
    z-index: 6;
}
