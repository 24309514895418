.table thead tr {
    height: 40px;
    background-color: #f7f7f7;
    text-align: left;
}

.table thead tr th {
    padding-left: 10px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px 1px 1px 1px;
}

.table tbody tr {
    height: 30px;
}

.expand-icon {
    padding-left: 10px
}

.table-row {
    background-color: white;
    display: inline-table !important;
}

.table-row td {
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 0px 1px 1px 1px;
}

.table-row td div {
    align-items: center;
    padding: 6px;
}

.treegrid-expander {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.tableBodyScroll tbody {
    display: block;
    max-height: 86vh;
    overflow-y: scroll;
}

.tableBodyScroll thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

